import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'gatsby'

class TestimonialVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      url: '',
    }

    /**
     * Video Player
     * @type {ReactPlayer}
     */
    this._player = null
  }

  componentWillMount() {
    this.setState({
      url: this.props.url,
    })
  }

  componentWillUnmount() {
    if (this._player) {
      this._player = null
    }
  }

  playVideo = () => {
    this.setState({ isPlaying: true })
  }

  onEnded = () => {
    this.setState({ isPlaying: false })
  }

  ref = player => {
    this._player = player
  }

  render() {
    const { isPlaying, url } = this.state

    return (
      <section className="testimonial">
        <div className="container">
          <h2
            className="testimonial__heading
                        text--3
                        underline"
          >
            Testimonial
          </h2>

          {this.props.projectTitle ? (
            <h3
              className="testimonial__project-title
                            text--6"
            >
              {this.props.projectTitle}
            </h3>
          ) : null}

          <div className="testimonial__video-content">
            <div
              className={
                'testimonial__video-wrapper ' +
                (!isPlaying ? 'testimonial__video-wrapper--hidden' : '')
              }
            >
              <ReactPlayer
                className="testimonial__video-player"
                url={url}
                width="100%"
                height="100%"
                ref={this.ref}
                playing={isPlaying}
                onEnded={this.onEnded}
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      rel: 0,
                      controls: 2,
                    },
                  },
                }}
              />
            </div>

            {!isPlaying ? (
              <div
                id="testimonial-video-overlay"
                className="testimonial__overlay"
                onClick={() => this.playVideo()}
              >
                <button
                  className="testimonial__play-button"
                  onClick={() => this.playVideo()}
                >
                  <svg
                    width="100"
                    height="100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <g id="play" fill="currentColor" fillRule="nonzero">
                        <path d="M50 0C22.384 0 0 22.384 0 50s22.384 50 50 50 50-22.384 50-50S77.616 0 50 0zm23.925 54.014L44.101 74.532a4.878 4.878 0 0 1-5.03.301 4.888 4.888 0 0 1-2.607-4.315V29.482a4.888 4.888 0 0 1 2.607-4.315 4.878 4.878 0 0 1 5.03.301l29.824 20.518A4.878 4.878 0 0 1 76.035 50c0 1.603-.79 3.106-2.11 4.014z" />
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Play Video</span>
                </button>

                <img
                  src={this.props.thumbnail}
                  alt={this.props.alt}
                  className="testimonial__img"
                />
              </div>
            ) : null}
          </div>
          <p
            className="testimonial__author
                        text--5
                        underline"
          >
            {this.props.author}
          </p>
          <p
            className="testimonial__role
                        text--6"
          >
            {this.props.authorRole}
          </p>

          {this.props.link ? (
            <Link
              to={this.props.link}
              className="testimonial__cta
                            btn
                            btn--secondary
                            btn--blue"
            >
              View Project
            </Link>
          ) : null}
        </div>
      </section>
    )
  }
}

export default TestimonialVideo
