import React from 'react'

export const WordPressIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>WordPress</title>
      <g fill="#606060" fillRule="nonzero">
        <path d="M11.99 12.336H9.783l6.018 17.01 3.368-9.627-2.611-7.383h-2.33v-1.621h10.314v1.62h-2.415l6.017 17.012 2.164-6.182c2.837-7.92-2.326-10.387-2.326-12.154a3.2 3.2 0 0 1 3.48-3.187 16.664 16.664 0 0 0-11.477-4.56c-5.806 0-10.92 2.959-13.918 7.45h5.923v1.622zM3.263 19.985c0 6.403 3.599 11.963 8.883 14.773L4.599 13.426a16.668 16.668 0 0 0-1.336 6.56zM34.539 11.745c.239 1.409.166 2.992-.2 4.58h.066l-.253.723c-.15.522-.338 1.056-.553 1.58l-5.754 16.12c5.273-2.814 8.863-8.369 8.863-14.763 0-2.996-.79-5.807-2.17-8.24z" />
        <path d="M14.828 35.896c1.625.526 3.358.812 5.157.812 1.717 0 3.373-.26 4.933-.74L20.026 22.14l-5.198 13.755z" />
        <path d="M34.117 5.854A19.855 19.855 0 0 0 19.985 0 19.855 19.855 0 0 0 5.854 5.854 19.855 19.855 0 0 0 0 19.985a19.855 19.855 0 0 0 5.854 14.132 19.855 19.855 0 0 0 14.131 5.854 19.855 19.855 0 0 0 14.132-5.854 19.855 19.855 0 0 0 5.854-14.132 19.855 19.855 0 0 0-5.854-14.131zM19.985 38.56c-10.242 0-18.575-8.333-18.575-18.576C1.41 9.743 9.743 1.41 19.985 1.41c10.243 0 18.576 8.333 18.576 18.575 0 10.243-8.333 18.576-18.576 18.576z" />
      </g>
    </svg>
  )
}
