import React from 'react'

export const WooCommerceIcon = () => {
  return (
    <svg width="59" height="35" xmlns="http://www.w3.org/2000/svg">
      <title>WooCommerce</title>
      <g fillRule="nonzero" fill="none" opacity=".7">
        <path
          d="M5.414 0h47.482c3.005 0 5.437 2.452 5.437 5.48v18.27c0 3.029-2.432 5.48-5.437 5.48H35.868L38.205 35l-10.278-5.77H5.437C2.433 29.23 0 26.78 0 23.75V5.48C-.024 2.477 2.408 0 5.414 0z"
          fill="#6D6D6D"
        />
        <path
          d="M3.104 5.355c.335-.458.837-.698 1.506-.746 1.22-.097 1.914.48 2.08 1.732.742 5.03 1.555 9.29 2.416 12.78l5.237-10.036c.478-.915 1.076-1.396 1.793-1.444 1.053-.072 1.698.602 1.961 2.022.598 3.2 1.363 5.92 2.272 8.23.622-6.113 1.674-10.517 3.156-13.236.359-.674.885-1.01 1.579-1.06.55-.047 1.052.121 1.506.482.454.361.694.818.741 1.372a2.1 2.1 0 01-.239 1.155c-.932 1.733-1.698 4.645-2.32 8.688-.597 3.923-.812 6.98-.669 9.17.048.6-.048 1.13-.287 1.588-.287.529-.717.818-1.267.866-.622.048-1.267-.24-1.89-.89-2.223-2.287-3.993-5.704-5.284-10.253a451.473 451.473 0 00-3.443 6.931c-1.411 2.72-2.607 4.116-3.611 4.188-.646.048-1.196-.506-1.674-1.66-1.22-3.153-2.535-9.242-3.946-18.267-.095-.626.048-1.18.383-1.612zm51.388 3.778c-.86-1.516-2.128-2.43-3.826-2.792a6.243 6.243 0 00-1.291-.144c-2.296 0-4.16 1.203-5.62 3.61-1.243 2.046-1.865 4.308-1.865 6.787 0 1.853.383 3.441 1.148 4.765.86 1.516 2.128 2.43 3.826 2.791.454.097.885.145 1.291.145 2.32 0 4.185-1.203 5.62-3.61 1.243-2.07 1.865-4.332 1.865-6.81.024-1.878-.383-3.442-1.148-4.742zM51.48 15.8c-.335 1.589-.932 2.768-1.817 3.562-.694.626-1.34.89-1.937.77-.574-.12-1.052-.625-1.41-1.564a6.136 6.136 0 01-.431-2.19c0-.602.048-1.203.167-1.757a7.81 7.81 0 011.267-2.864c.79-1.18 1.627-1.66 2.487-1.492.574.12 1.053.626 1.411 1.564.287.747.43 1.493.43 2.19 0 .626-.047 1.228-.167 1.781zM39.523 9.133c-.86-1.516-2.152-2.43-3.826-2.792a6.241 6.241 0 00-1.291-.144c-2.296 0-4.161 1.203-5.62 3.61-1.243 2.046-1.865 4.308-1.865 6.787 0 1.853.383 3.441 1.148 4.765.86 1.516 2.128 2.43 3.826 2.791.454.097.884.145 1.291.145 2.32 0 4.185-1.203 5.62-3.61 1.243-2.07 1.865-4.332 1.865-6.81 0-1.878-.383-3.442-1.148-4.742zM36.486 15.8c-.335 1.589-.933 2.768-1.817 3.562-.694.626-1.34.89-1.937.77-.574-.12-1.052-.625-1.411-1.564a6.146 6.146 0 01-.43-2.19c0-.602.047-1.203.167-1.757a7.806 7.806 0 011.267-2.864c.79-1.18 1.626-1.66 2.487-1.492.574.12 1.052.626 1.41 1.564.288.747.431 1.493.431 2.19a7.144 7.144 0 01-.167 1.781z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
