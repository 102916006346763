import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import TextBlock from '../../components/TextBlock/TextBlock'
import Slider from '../../components/Slider/Slider'
import Goals from '../../components/Goals/Goals'
import Outcomes from '../../components/Outcomes/Outcomes'
import BottomNav from '../../components/BottomNav/BottomNav'
import TestimonialVideo from '../../components/TestimonialVideo/TestimonialVideo'

import Logo from '../../images/logo-vooba.png'
import { WooCommerceIcon } from '../../components/icons/WooCommerceIcon'
import { WordPressIcon } from '../../components/icons/WordPressIcon'
import { SketchIcon } from '../../components/icons/SketchIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { CssIcon } from '../../components/icons/CssIcon'

const VoobaPage = () => {
  const services = [
    {
      id: 1,
      text: 'Providing development resources',
    },
    {
      id: 2,
      text: 'Project management',
    },
  ]

  const goals = [
    {
      id: 11,
      text: 'Provide WordPress/Ecommerce development based on custom design',
    },
    {
      id: 22,
      text:
        'Support B2B customers with technical queries during and after the development process',
    },
    {
      id: 33,
      text: 'On-going support and collaboration',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: '6+',
      subtitle: 'months of an ongoing partnership',
    },
    {
      id: 222,
      title: '5+',
      subtitle: 'projects delivery on-time',
    },
  ]

  const slides = [
    {
      id: 1,
      img: 'https://cdn.emersoft.co/emersoft-website/vooba/vooba-slider-1.jpg',
      alt: 'Emersoft Team in Vooba office with Tony',
    },
    {
      id: 2,
      img: 'https://cdn.emersoft.co/emersoft-website/vooba/vooba-slider-2.jpg',
      alt: 'Vooba with clients',
    },
  ]

  const techs = [
    {
      id: 1111,
      icon: WooCommerceIcon(),
    },
    {
      id: 2222,
      icon: WordPressIcon(),
    },
    {
      id: 3333,
      icon: SketchIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
    {
      id: 5555,
      icon: CssIcon(),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Vooba"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
          `Vooba`,
          `WordPress`,
          `Woocommerce`,
        ]}
      />

      <HeaderImg
        header="header-img--vooba"
        img={Logo}
        wrapper="header-img__img-wrapper--vooba"
      />
      <ProjectOverview
        title="Vooba"
        link="https://www.vooba.co.uk/"
        category='Digital Agency'
        services={services}
        technologies={techs}
        subtitle="Ongoing Partner Collaboration"
        paragraph1="Vooba is a transparent digital marketing agency in Sussex, UK with a Passion for Digital. They have clients all over the UK and Europe and offer digital solutions for all your design and marketing needs."
      />
      <TestimonialVideo
        url="https://www.youtube.com/watch?v=lOdhU3ZMVT8"
        author="Tony Humberstone"
        authorRole="Managing Director"
        thumbnail="https://cdn.emersoft.co/emersoft-website/vooba/tony-humberstone-emersoft-testimonial-vooba.jpg"
        alt="Tony Humberstone"
      />
      <TextBlock
        heading="Brief"
        paragraph1="Our client had been on a lookout for a strong technical partner with a proven track record developing highly customized WordPress websites."
        paragraph2="We came into the picture with our robust development process, fresh energy, and high UI/UX skillsets to support and benefit Vooba’s large base of small business customers."
      />
      <Slider slides={slides} />
      <Goals goalsList={goals} />
      <TextBlock
        heading="Our Process"
        paragraph1="We started off by integrating both companies’ processes and teams, which after nearly 6 months resulted in automating the management and WordPress development process."
      />
      <Outcomes outcomes={outcomes} fourItems />
      <BottomNav
        heading="See More"
        firstLink="/our-work/lightspeedvt"
        firstHeading="LightSpeed VT"
        secondLink="/our-work/radium"
        secondHeading="Radium"
      />
    </Layout>
  )
}

export default VoobaPage
